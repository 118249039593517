import * as React from "react";
import { Link, PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import Button from "../../../components/basic-components/button/button";
import { ButtonTypesEnum } from "../../../components/basic-components/button/types";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/cocuk-oksuruk-big.webp";
import { useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ChildCoughPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Çocuklarda Öksürüğe Ne İyi Gelir?",
        description:
          "Çocuklarda Öksürüğe Ne İyi Gelir? Çocuklarda öksürük ve nedenleri hakkında daha detaylı bilgiye sahip olmak için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Çocuklarda Öksürüğe Ne İyi Gelir?","item": "${location.href}"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Çocuklarda Öksürüğe Ne İyi Gelir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-09-01",
              "dateModified": "2021-09-01"
            }
          `}
        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Çocuklarda Öksürüğe Ne İyi Gelir?","acceptedAnswer":{"@type":"Answer","text":"Çocuklarda öksürük genellikle kendiliğinden geçen bir durumdur. Ancak bazen öksürüğün altında ciddi bir rahatsızlık yatabilir. Özellikle uzun süre geçmeyen kronik öksürük, kanlı öksürük, öksürüğe eşlik eden yüksek ateş gibi durumların varlığında çocuklar mutlaka bir sağlık kuruluşuna götürülmelidir."}},{"@type":"Question","name":"Öksürük Nedir?","acceptedAnswer":{"@type":"Answer","text":"Öksürük, hava yollarında var olan mukus, mikroorganizma ve diğer yabancı maddelerin vücut dışına atılmasını sağlayan bir reflekstir."}},{"@type":"Question","name":"Çocuklarda Öksürük Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Çocuklarda öksürük, ebeveynlerin en sık karşılaştığı problemlerden biridir. Her ne kadar öksürük vücudun yabancı madde ve mikroplardan kendini korumak için geliştirdiği bir refleks olsa da çocukların sık sık öksürüğe yakalanması ebeveynleri endişelendirir."}},{"@type":"Question","name":"Okulla Birlikte Başlayan Öksürüğe Bisolnatur Nasıl Etki Eder?","acceptedAnswer":{"@type":"Answer","text":"Bisolnatur, okulların açılmasıyla sık sık öksürük problemi yaşayan çocuklarda öksürüğü rahatlatır, boğazdaki tahrişi önler. Peki tüm bunları nasıl yapar? Bisolnatur, içerisinde Poliflav M.A. kompleksi içerir."}}]}`}</script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/cocuk-oksuruk-big.webp"
            alt="Öksüren çocuk görseli"
            width={1680}
            height={450}
          />
          <div className={styles.filter} />
          <Container>
            <Heading>Çocuklarda Öksürüğe Ne İyi Gelir?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#oksuruk-nedir"
                  title="Öksürük nedir?"
                >
                  Öksürük nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#cocuklarda-oksuruk-nedenleri-nelerdir"
                  title="Çocuklarda Öksürük Nedenleri Nelerdir?"
                >
                  Çocuklarda Öksürük Nedenleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#cocuklarda-kuru-oksuruk-sebepleri-nelerdir"
                  title="Çocuklarda Kuru Öksürük Sebepleri Nelerdir?"
                >
                  Çocuklarda Kuru Öksürük Sebepleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#cocuklarda-balgamli-oksuruk-sebepleri-nelerdir"
                  title="Çocuklarda Balgamlı Öksürük Sebepleri Nelerdir?"
                >
                  Çocuklarda Balgamlı Öksürük Sebepleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#cocuklarda-oksuruge-ne-iyi-gelir"
                  title="Çocuklarda Öksürüğe Ne İyi Gelir?"
                >
                  Çocuklarda Öksürüğe Ne İyi Gelir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bisolnatur"
                  title="Bisolnatur ile Öksürüğünü Rahatlat"
                >
                  Bisolnatur ile Öksürüğünü Rahatlat
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#okulla-birlikte-baslayan-oksuruge-bisolnatur-nasil-etki-eder"
                  title="Okulla Birlikte Başlayan Öksürüğe Bisolnatur Nasıl Etki Eder?"
                >
                  Okulla Birlikte Başlayan Öksürüğe Bisolnatur Nasıl Etki Eder?
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <section className={styles.whatHolder}>
            <p className={classNames("blue bold", styles.intro)}>
              İnsanlarda üst solunum yolları, mukus adı verilen yapışkan bir
              madde üretir. Üretilen bu mukus, solunum sırasında dışarıdan
              havayla birlikte alınan yabancı partiküllerin tutulmasını sağlar.
              Böylece yabancı partiküller daha alt solunum yollarına inemez. Bu
              mekanizma sayesinde vücut kendini enfeksiyonlardan korumuş olur.
              Solunum yollarında üretilen mukusun tahriş olduğu durumlarda ise
              vücut bu tahriş edici maddeden kurtulmak için öksürük refleksini
              devreye sokar.
            </p>
            <Level>
              <Heading id="oksuruk-nedir" className="orange underline">
                Öksürük Nedir
              </Heading>
              <p>
                <Link className="blue underline" to={"/oksuruk/oksuruk-nedir/"}>
                  Öksürük,
                </Link>{" "}
                hava yollarında var olan mukus, mikroorganizma ve diğer yabancı
                maddelerin vücut dışına atılmasını sağlayan bir reflekstir.
                reflekstir. Öksürük özellikle çocuklarda çok sık olarak ortaya
                çıkan bir problemdir. Genellikle kış aylarında ve okul döneminde
                kendini gösteren öksürük, çocuğun gün içerisinde rahat olmasını,
                gece huzurlu bir uyku uyumasını engelleyebilir. Ayrıca öksürük
                ile havaya saçılan partiküller; çocuğun enfeksiyonu
                kardeşlerine, kreş veya okul arkadaşlarına bulaştırmasına neden
                olabilir. Dolayısıyla tek bir kişide başlayan rahatsızlık,
                salgına dönüşebilir. Çocuğun öksürüğü çabuk atlatması,
                çevresindeki kişilere hastalığı yaymaması için ebeveynler
                mutlaka öksürük rahatlatıcı ve boğazdaki tahrişi yatıştıran
                çözümlere yönelmelidir.
              </p>
              <Heading
                id="cocuklarda-oksuruk-nedenleri-nelerdir"
                className="orange underline"
              >
                Çocuklarda Öksürük Nedenleri Nelerdir?
              </Heading>
              <div className={styles.coughBoyHolder}>
                <p>
                  Çocuklarda öksürük, ebeveynlerin en sık karşılaştığı
                  problemlerden biridir. Her ne kadar öksürük vücudun yabancı
                  madde ve mikroplardan kendini korumak için geliştirdiği bir
                  refleks olsa da çocukların sık sık öksürüğe yakalanması
                  ebeveynleri endişelendirir. Öksürük pek çok farklı duruma
                  bağlı olarak ortaya çıkabilir. Ancak öksürük genellikle ciddi
                  bir problemin göstergesi değildir. Bu nedenle aileler
                  çocukları öksürüğe yakalandığında korkmamalı, çocuğun
                  öksürüğünü hafifletecek çözümlere başvurmalıdır.
                  <br />
                  <br />
                  Öksürük, kuru ve balgamlı olmak üzere iki farklı alt gruba
                  ayrılabilir. Balgamlı veya diğer adıyla ıslak öksürük,
                  öksürürken balgam adı verilen mukusun dışarı atıldığı ya da
                  dışarı atılıyormuş gibi seslerin çıktığı gruptur. Yani
                  balgamlı öksürük olan bir kişide öksürürken biraz daha
                  hırıltılı sesler duyulur. Kuru öksürük ise herhangi bir balgam
                  çıkışının olmadığı, boğazda birikmiş bir mukus sesinin
                  alınmadığı öksürük türüdür. Kuru ve ıslak öksürüğe sebep olan
                  hastalıklar farklıdır ama bazı rahatsızlıklar hem kuru hem de
                  ıslak öksürük gelişmesine yol açabilir.
                </p>
                <StaticImage
                  className={styles.coughBoy}
                  src="../../../images/sick-cough-child.png"
                  alt="Öksüren çocuk görseli"
                />
              </div>
              <Heading
                id="cocuklarda-kuru-oksuruk-sebepleri-nelerdir"
                className="orange underline"
              >
                Çocuklarda Kuru Öksürük Sebepleri Nelerdir?
              </Heading>
              <p>
                Kuru yani balgamsız öksürük, farklı rahatsızlıklara bağlı olarak
                oluşur.{" "}
                <Link
                  className="blue underline"
                  to={"/oksuruk/kuru-oksuruk-nedir/"}
                >
                  Kuru öksürük,
                </Link>{" "}
                solunum yollarında mukus artışı olmaksızın öksürme refleksinin
                artış gösterdiği bir durumdur. Aslında kuru öksürük; farenks
                yani halk arasında boğaz olarak bilinen yapıdaki irritasyona,
                tahrişe bağlı olarak ortaya çıkar.
                <br />
                <br />
                Çocuğunuzun öksürüğünün kuru mu yoksa balgamlı mı olduğunu
                anlamak için öksürürken çocuğunu dinleyebilirsiniz. Çocuğunuz
                öksürürken temiz olarak adlandırılabilecek bir ses geliyorsa
                boğazından hırıltı benzeri bir ses gelmiyorsa öksürük türünü
                kuru olarak belirleyebilirsiniz.
                <br />
                <br />
                Çocuklarda kuru öksürükle birlikte hapşırma, baş ağrısı,
                kırgınlık gibi belirtiler görülebilir. Eğer çocuğunuz okuldan,
                kreşten geldiğinde halsizlik, iyi hissetmeme tarifliyor ve
                öksürüyorsa hastalık kapmış olabilir. Bu gibi durumlarda
                öksürüğü rahatlatmak çocuğun kendini daha hızlı toparlamasını
                sağlayabilir. Çünkü kuru öksürük gece yatarken şiddetini artırıp
                çocuğunuzun uykusunu bozabilir. Yeterince uyuyamayan,
                dinlenemeyen çocuk ise hem daha geç toparlanır hem de gün
                içerisinde enerjisini yükseltemez.
              </p>
              <Heading
                id="cocuklarda-balgamli-oksuruk-sebepleri-nelerdir"
                className="orange underline"
              >
                Çocuklarda Balgamlı Öksürük Sebepleri Nelerdir?
              </Heading>
              <p>
                Islak ya da diğer adıyla balgamlı öksürük, solunum yollarında
                mukus salgısının artması sonucu ortaya çıkar. Aslında mukus,
                solunum yollarının sağlığını koruyan, dışarıdan alınan irritan
                maddelerin solunum epiteline zarar vermesini engelleyen bir
                maddedir. Ancak bazı durumlarda mukus, olması gerekenden daha
                fazla salgılanır. Fazla mukus, solunum yollarında tıkanıklık
                hissi oluşturarak nefes alıp vermeyi zorlaştırabilir. Vücut bu
                fazla mukustan kurtulmak için öksürük refleksini devreye sokar.
                Çocuklarda mukus salgısını artıran durumlar balgamlı öksürüğe
                neden olur. Aileler çocuklarının ıslak öksürük sorunu
                yaşadıklarını, öksürürken balgam çıkarmasından anlayabilir.
                Ancak özellikle küçük çocuklar, öksürükle balgam
                çıkaramayabilir. Bu durumda aileler çocuklarının öksürük
                sırasında hırıltılı sesler çıkardığını, çocuğun boğazında bir
                şey takılıyormuş hissinin oluştuğunu gözlemleyebilir.
                <br />
                <br />
                <Link
                  className="blue underline"
                  to={"/oksuruk/balgamli-oksuruk/"}
                >
                  Balgamlı öksürük
                </Link>{" "}
                şikayeti olan çocuklarda burun akıntısı, burun tıkanıklığı, ateş
                gibi belirtiler de görülebilir. Bu belirtilere ek olarak ek
                olarak özellikle küçük yaştaki çocuklarda, öksürük atakları
                sonrası iç çekme benzeri bir nefes alma davranışı izlenebilir.
                Bu durum çocuğun öksürürken yeterince nefes alamamasından
                kaynaklanır. Öksürük atağı bittiğinde derin bir nefes alan
                çocukta bu soluk alma davranışı iç çekme şeklinde ortaya çıkar.
              </p>
              <div className={styles.balgamCough}>
                <StaticImage
                  className={styles.coughGirl}
                  src="../../../images/sick-cough-girl.png"
                  alt="Öksüren çocuk görseli"
                />
                <p>
                  Balgamlı öksürük sorunu yaşayan çocukların öksürük atakları
                  gece saatlerinde artış gösterebilir. Bunun sebebi artmış
                  mukusun gündüz vakitlerinde burunda toplanmasıdır. Ancak saat
                  ilerleyip çocuk yattığında mukus salgısı daha aşağılara iner.
                  Boğaz bölgesine inen mukus, öksürme refleksini uyarır.
                  Dolayısıyla çocuklar gece saatlerinde daha sık öksürük atağı
                  yaşar.
                  <br />
                  <br />
                  Hem balgamlı hem de kuru öksürük, çocukların boğazını tahriş
                  edebilir. Bu irritasyon, hastalığa bağlı tahrişle
                  birleştiğinde solunum yollarında ciddi bir hasar ortaya
                  çıkabilir. Sık sık ya da uzun süre öksüren çocuklarda, boğazda
                  yer alan ses telleri de etkilenebilir ve ses kısıklığı ortaya
                  çıkabilir. Öksürüğe bağlı ses kısıklığı, çocukların hem eğitim
                  hem de sosyal hayatını etkileyen bir durumdur. Aileler,
                  çocuklarının ses tellerinin bu denli etkilenmesine engel olmak
                  için öksürük kronik ve şiddetli bir hal almadan müdahale
                  etmelidir. Çocuktaki öksürüğü rahatlatmak aynı zamanda ses
                  tellerinin de korunmasına yardımcı olur.
                </p>
              </div>
              <Heading
                id="cocuklarda-oksuruge-ne-iyi-gelir"
                className="orange underline"
              >
                Çocuklarda Öksürüğe Ne İyi Gelir?
              </Heading>
              <p>
                Çocuklarda öksürük genellikle kendiliğinden geçen bir durumdur.
                Ancak bazen öksürüğün altında ciddi bir rahatsızlık yatabilir.
                Özellikle uzun süre geçmeyen kronik öksürük, kanlı öksürük,
                öksürüğe eşlik eden yüksek ateş gibi durumların varlığında
                çocuklar mutlaka bir sağlık kuruluşuna götürülmelidir.
                <br />
                <br />
                Zaten bir çocuk yıl boyu pek çok kez basit viral enfeksiyon
                geçirir, pek çok kez öksürük sorunuyla karşılaşır. Dolayısıyla
                bu gibi durumlarda aileler, her zaman ilaca başvurmak yerine
                basit ve doğal çözümlerle öksürük yatıştırmaya çalışabilir.
              </p>
              <div className={styles.coughBoy1Holder}>
                <p>
                  Çocuklarda öksürüğün geçirilmesi için uygulanabilecek ilk
                  yöntem nemli havadır. Hava nemlendirici cihazlarla odanızın
                  veya duşta sıcak suyu açıp banyo havasının, nemli olmasını
                  sağlayabilirsiniz. Çocuğunuzun bu nemli havayı 20 dakika kadar
                  solumasını sağlamak, öksürüğü hafifletmeye yardımcı olabilir.
                  <br />
                  <br />
                  Ilık sıvılar tüketmek de çocukların boğazının rahatlamasına
                  katkıda bulunur. Ilık süt, bitki çayı veya sadece su bile bu
                  konuda size yardımcı olabilir. Öksürüğün yanı sıra viral
                  enfeksiyon gibi durumlarda da bol sıvı tüketmek hastalığın
                  daha çabuk atlatılmasını sağlayabilir.
                </p>
                <StaticImage
                  className={styles.coughBoy1}
                  src="../../../images/air.png"
                  alt="Hava nemlendirici görseli"
                />
              </div>
              <p>
                Dinlenme, öksürüğün geçirilmesinde önemlidir. Çocuğun çok aktif
                hareket etmemesi, sesini çok fazla kullanmaması öksürüğün daha
                çabuk gitmesine yardımcı olabilir. Dinlenme hem öksürüğün hem de
                hastalığın daha iyiye gitmesi için bu denli önemli iken öksürük
                atakları çocuğun dinlenmesine engel olabilir. Özellikle gece
                saatlerinde artan öksürük krizleri çocuğun uykusunu böler, çocuk
                sabah yeterince dinlenmemiş olarak kalkar. Dolayısıyla
                ebeveynler çocuğunun daha iyi dinlenebilmesi için öksürük
                rahatlatıcı şuruplar kullanabilir. Böylece çocuk daha rahat
                uyur, daha iyi dinlenir.
                <br />
                <br />
                Öksürük sorunu yaşayan çocuklarda hijyene çok dikkat
                edilmelidir. Öksürürken çocuğun ağzının ve burnunun kapatılması,
                çocuktaki partiküllerin çevreye yayılmasını önler. Çocuğun
                ellerini sık sık yıkamak ve çocuğa duş aldırmak da hijyenin
                önemli bir parçasıdır. Ailelerin hem kendilerinin hijyen
                kurallarına uyması hem de çocuklarına bu kuralları öğretmesi,
                çocuğun ileride öksürük sorunu yaşamasının önüne geçmek için
                yardımcıdır.
              </p>
            </Level>
          </section>
          <section id="bisolnatur" className={styles.bisolnatur}>
            <Level>
              <Level>
                <Heading>Bisolnatur ile Öksürüğünü Rahatlat</Heading>
              </Level>
              <div className={classNames(styles.productHolder)}>
                <div className={styles.productSide}>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/bisolnatur-urun-yeni.png"
                    alt="Bisolnatur ürün görseli"
                  />
                  <Button
                    to={"/urunler/bisolnatur/"}
                    type={ButtonTypesEnum.orangeBorder}
                  >
                    Detaylı bilgi almak için tıklayın
                  </Button>
                </div>
                <p>
                  Bisolnatur, doğal içerikli bir öksürük şurubudur. Farklı bir
                  deyişle Bisolnatur kimyasal bir ilaç değildir. Çocuğunuzda
                  oluşabilecek hem kuru hem de balgamlı öksürüğü rahatlatır.
                  <br />
                  <br />
                </p>
                <p>
                  Okulların, kreşlerin açılmasıyla birlikte çocuklarda görülen
                  solunum yolu hastalıkları sayısında ciddi bir artış görülür.
                  Solunum yolu hastalıklarının en önemli belirtilerinden biri
                  öksürük olduğu için bu dönemde, çocuklar arasında öksürük
                  yaygınlaşabilir. Öksürük çocuğunuzun huzurunu kaçırır, kreşte
                  veya okuldaki uyumunu bozar. Çocuğunuz öksürük nedeniyle okula
                  gidemeyebilir. Bu durum çocuğunuzun gerek akademik gerek de
                  sosyal anlamda arkadaşlarından geri kalmasına yol açabilir.
                  Çocuğunuzun okul hayatının kesintiye uğramaması, her sabah
                  sağlıklı bir şekilde okuluna gidebilmesi için bitkisel içerik
                  ve baldan oluşan öksürük şurubu Bisolnatur’a
                  başvurabilirsiniz. Doğal içerikli öksürük şurubu Bisolnatur,
                  kuru ve balgamlı öksürüğü rahatlatır, çocuğunuzun sağlığını
                  desteklemek için güvenilir bir çözümdür.
                </p>
              </div>
              <Level>
                <Heading id="okulla-birlikte-baslayan-oksuruge-bisolnatur-nasil-etki-eder">
                  Okulla Birlikte Başlayan Öksürüğe Bisolnatur Nasıl Etki Eder?
                </Heading>
                <p>
                  Bisolnatur, okulların açılmasıyla sık sık öksürük problemi
                  yaşayan çocuklarda öksürüğü rahatlatır, boğazdaki tahrişi
                  yatıştırır, balgamın atılmasını kolaylaştırır. Peki tüm
                  bunları nasıl yapar? Bisolnatur, içerisinde Poliflav M.A.
                  kompleksi içerir. Bu kompleks, mızrak yapraklı sinir otundan
                  elde edilen polisakkarit fraksiyonu ile kekikten ekstre edilen
                  flavonoid fraksiyonundan oluşur. Bisolnatur ayrıca mukoadezif
                  yani mukoza yapısına yapışma özelliği olan balı da içeriğinde
                  barındırır. Poliflav M.A. kompleksi ve bal birlikte mukoza
                  tabakasının üzerine bir ‘film’ tabakası oluşturur. Bu tabaka,
                  mukozanın dış çevreden irritan yani tahriş edici maddelerle
                  temas etmesini önler. Böylece mukoza tahrişine bağlı ortaya
                  çıkan öksürük refleksi yatıştırılmış olur. Poliflav M.A.
                  kompleksi ve bal ayrıca mukusun nemlendirilmesini
                  destekleyerek vücuttan atılımını kolaylaştırır. Bisolnatur tüm
                  bu özellikleri sayesinde çocuğunuzun sağlığını korumada
                  yardımcıdır.
                </p>
                <StaticImage
                  className={styles.imageLast}
                  src="../../../images/school-girls.png"
                  alt="Okul çantalı çocuklar görseli"
                />
                <p>
                  Öksürüğü rahatlatıcı etkiye sahip Bisolnatur, doğal şeftali,
                  portakal ve limon aromalarını içerir. İçerdiği bu lezzetli
                  aromalar sayesinde çocuğunuza Bisolnatur içirirken zorluk
                  yaşamazsınız. Bisolnatur, ailelerin en büyük çekincelerinden
                  biri olan koruyucu madde açısından da oldukça güvenilirdir.
                  Dolayısıyla çocuğunun sağlığını doğal yollarla korumak isteyen
                  ebeveynler, Bisolnatur’u tercih edebilir. Bisolnatur öksürük
                  şurubu hiçbir renklendirici, koruyucu, tatlandırıcı içermez.
                  Ayrıca Bisolnatur’da gluten ve paraben bulunmaz.
                  <br />
                  <br />
                  Bisolnatur ebeveynlerin gönül rahatlığıyla tercih edebileceği
                  bir üründür. Ayrıca Bisolnatur, Sağlık Bakanlığı onaylıdır ve
                  1 yaşından büyük herkesin kullanımına uygundur.
                  <br />
                  <br />
                  “Bisolnatur’u hangi ölçülerde kullanacağım?’’ sorusu, çok
                  sayıda anne baba tarafından sorulmaktadır. Bisolnatur, 1-6 yaş
                  arası çocuklara her seferinde bir ölçü (5 ml) olmak üzere
                  günde iki kez verilmelidir. 6 yaş üstü çocuklar için ise her
                  seferinde 2 ölçü (10 ml) olarak günde iki kez kullanımı
                  uygundur. Bisolnatur sadece çocuklarda değil yetişkinlerde de
                  etkilidir. Öksürük sorunu yaşayan yetişkinler de
                  Bisolnatur’dan fayda görür. Öksürüğünü rahatlatmak isteyen
                  yetişkinler Bisolnatur’u ihtiyaca göre günde 4 kereye kadar
                  kullanabilir. Yetişkinlerin de her seferinde iki ölçek (10 ml)
                  kullanması tavsiye edilmektedir.
                  <br />
                  <br />
                  “Bisolnatur’u ne kadar süre kullanmalıyım?’’ sorusu ailelerin
                  merak ettiği bir diğer husustur. Bisolnatur’u, gerek
                  çocuğunuzda gerekse kendinizde öksürük şikayeti geçene kadar
                  kullanabilirsiniz. Çünkü Bisolnatur ilaç değildir, öksürüğü
                  rahatlatır ve sağlığın korunmasına yardımcı olur. Ancak
                  öksürük şikayeti bir haftadan uzun süredir devam ediyorsa,
                  öksürüğe eşlik eden ateş gibi belirtiler varsa doktora
                  başvurmanız faydalı olur.
                  <br />
                  <br />
                  Çocuklarınızın öksürük nedeniyle okul hayatlarının aksamasını
                  istemiyorsanız size en yakın eczaneden Bisolnatur’u satın
                  alabilirsiniz! Sağlıklı günler dileriz.
                </p>
              </Level>
            </Level>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2021-09-01</p>
          <p>Son güncellenme Tarihi: 2021-09-01</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default ChildCoughPage;
