// extracted by mini-css-extract-plugin
export var balgamCough = "styles-module--balgamCough--8YM5e";
export var bisolnatur = "styles-module--bisolnatur--1k1uk";
export var breadcrumb = "styles-module--breadcrumb--jmkXu";
export var coughBoy = "styles-module--coughBoy--W9bUa";
export var coughBoy1 = "styles-module--coughBoy1--y+5hH";
export var coughBoy1Holder = "styles-module--coughBoy1Holder--ERDqG";
export var coughBoyHolder = "styles-module--coughBoyHolder--g8FBq";
export var coughGirl = "styles-module--coughGirl--myO28";
export var filter = "styles-module--filter--aiebr";
export var header = "styles-module--header--d6mXU";
export var holder = "styles-module--holder--kRHvv";
export var image = "styles-module--image--csEYn";
export var imageFirst = "styles-module--imageFirst--vabgV";
export var imageLast = "styles-module--imageLast--ak+Vk";
export var intro = "styles-module--intro--5+cZ9";
export var productHolder = "styles-module--productHolder--FlBm+";
export var productSide = "styles-module--productSide--6NoBg";
export var whatHolder = "styles-module--whatHolder--KquBC";